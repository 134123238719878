import React from 'react';
import styles from './breadcrumbs.module.scss';
import { Link } from 'react-router-dom';
import cx from '../../../utils/classnames';
import common from '../../../utils/common.module.scss';
import { sanatizedLocationSearch } from '../../../routes-fpr';
export var Breadcrumbs = function (_a) {
    var breadcrumbs = _a.breadcrumbs, getValueByTitle = _a.getValueByTitle;
    return (React.createElement("div", { className: cx(styles.breadcrumbs, common.breadcrumbsSpacing) },
        React.createElement("div", { className: styles.content }, breadcrumbs.map(function (item, index) {
            return item.url ? (React.createElement(Link, { key: index, to: {
                    pathname: item.url,
                    search: sanatizedLocationSearch() + (item.search ? '&' + item.search : ''),
                } },
                React.createElement("div", { className: styles.item }, getValueByTitle(item.name)))) : (React.createElement("a", { key: index, className: styles.inactive },
                React.createElement("div", { className: styles.item }, getValueByTitle(item.name))));
        }))));
};
export default Breadcrumbs;
