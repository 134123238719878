var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useContext, useEffect } from 'react';
import styles from './points-assist-view.module.scss';
import utils from '../../utils/utils.module.scss';
import cx from '../../utils/classnames';
import { TypeaheadComboControl } from '../../components/common/type-ahead-combo/type-ahead-combo';
import { InputWithLabel } from '../../components/common/Input-with-label/input-with-label';
import { DatePickerWithLabel } from '../../components/common/datepicker-with-label/datepicker-with-label';
import { Footnote } from '../../components/common/footnote/footnote';
import { RadioItem } from '../../components/common/radio-item/radio-item';
import { PointsAssistContext } from '../../context/points-assist-context';
import PointsAssistService from '../../services/poinsts-assist-service/points-assist-service';
export var getId = function (_a) {
    var paCode = _a.paCode;
    return paCode;
};
export var getDesc = function (_a) {
    var _b = _a.address, address1 = _b.address1, city = _b.city, state = _b.state, postalCode = _b.postalCode;
    return "".concat(address1, ", ").concat(city, ", ").concat(state, " ").concat(postalCode === null || postalCode === void 0 ? void 0 : postalCode.substr(0, 5));
};
export var SUBMIT_REPAIR = 'REPAIR';
export var SUBMIT_SALE = 'SALE';
var postProcessResponse = function (resp) {
    var _a;
    //if (resp.errorCode == 'MATCHING_TRANSACTION_NOT_FOUND') return 'SUCCESS';
    if (resp.errorCode == 'SUCCESS' && !((_a = resp.payload) === null || _a === void 0 ? void 0 : _a.value.pointDetails.find(function (detail) { return detail.pointsCategory == 'F'; })))
        return 'SERVICE_SUCCESS_BUT_NO_AWARDABLE_ITEMS';
    return resp.errorCode;
};
export var submitForReview = function (_a) {
    var type = _a.type, chosenDealer = _a.chosenDealer, vin = _a.vin, invoiceNumber = _a.invoiceNumber, purchaseDate = _a.purchaseDate, pending = _a.pending, history = _a.history, retry = _a.retry, setDisabled = _a.setDisabled;
    return __awaiter(void 0, void 0, void 0, function () {
        var service, request, resp, redirParams, tooSoon, code;
        return __generator(this, function (_b) {
            switch (_b.label) {
                case 0:
                    service = new PointsAssistService();
                    request = {
                        vin: vin,
                        partnerCategory: chosenDealer === null || chosenDealer === void 0 ? void 0 : chosenDealer.partnerCategory,
                        partnerCode: chosenDealer === null || chosenDealer === void 0 ? void 0 : chosenDealer.paCode,
                        partnerCountry: chosenDealer === null || chosenDealer === void 0 ? void 0 : chosenDealer.countryCode,
                        isPendingFlag: pending,
                    };
                    redirParams = "?isPendin=".concat(pending, "&vin=").concat(vin, "&partnerCategory=").concat(chosenDealer === null || chosenDealer === void 0 ? void 0 : chosenDealer.partnerCategory, "&paCode=").concat(chosenDealer === null || chosenDealer === void 0 ? void 0 : chosenDealer.paCode);
                    tooSoon = false;
                    if (!(type == SUBMIT_REPAIR)) return [3 /*break*/, 2];
                    if (!(chosenDealer && (vin === null || vin === void 0 ? void 0 : vin.length) >= 8 && (invoiceNumber === null || invoiceNumber === void 0 ? void 0 : invoiceNumber.length) >= 6)) {
                        setDisabled(false);
                        return [2 /*return*/];
                    }
                    request.repairOrderNumber = invoiceNumber;
                    redirParams += "&invoice=".concat(invoiceNumber);
                    return [4 /*yield*/, service.lookupRepair(request)];
                case 1:
                    resp = _b.sent();
                    return [3 /*break*/, 4];
                case 2:
                    if (!(chosenDealer && (vin === null || vin === void 0 ? void 0 : vin.length) >= 8 && purchaseDate)) {
                        setDisabled(false);
                        return [2 /*return*/];
                    }
                    request.acquisitionDate = purchaseDate.toISOString();
                    redirParams += "&acquisitionDate=".concat(purchaseDate.toISOString());
                    return [4 /*yield*/, service.lookupSale(request)];
                case 3:
                    resp = _b.sent();
                    _b.label = 4;
                case 4:
                    if (resp.errorCode) {
                        if (resp.message.includes('<= 30')) {
                            tooSoon = true;
                        }
                        code = postProcessResponse(resp);
                        history.push("/rewards-history/points-assist-review".concat(redirParams, "&result=").concat(code).concat(tooSoon ? '&lessThan30=true' : '').concat(retry ? '&retry=true' : ''));
                        //if (!pending) window.location.reload(false);
                    }
                    return [2 /*return*/];
            }
        });
    });
};
export var DealerLookup = function (_a) {
    var dealer = _a.dealer, setChosenDealer = _a.setChosenDealer, getId = _a.getId, getDesc = _a.getDesc, validate = _a.validate, getValueByTitle = _a.getValueByTitle;
    var dealers = useContext(PointsAssistContext)[0].dealers;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { style: { height: '56px' } },
            React.createElement(TypeaheadComboControl, { id: 'dealer1', label: getValueByTitle('dealer-name'), dataSource: dealers, setSelected: setChosenDealer, getId: getId, getDesc: getDesc, selected: dealer, ariaDescribedBy: 'dealerError', ariaInvalid: validate && !dealer })),
        React.createElement("div", { className: cx(styles.controlWidth, styles.controlMarginTop), style: { marginBottom: '20px' } },
            React.createElement(Footnote, { text: getValueByTitle('dealer-name-note') })),
        validate && !dealer && (React.createElement("div", { id: 'dealerError', className: utils.error }, getValueByTitle('required')))));
};
export var InvoiceInput = function (_a) {
    var invoice = _a.invoice, setInvoiceNumber = _a.setInvoiceNumber, validate = _a.validate, getValueByTitle = _a.getValueByTitle;
    var isError = validate && !((invoice === null || invoice === void 0 ? void 0 : invoice.length) >= 6);
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(InputWithLabel, { id: 'invoice', label: getValueByTitle('invoice-number'), minLength: 6, maxLength: 6, onChange: function (evnt) {
                    var inv = evnt.target.value
                        .trim()
                        .toUpperCase()
                        .replace(/[^A-Z0-9]/g, '');
                    setInvoiceNumber(inv);
                    evnt.target.value = inv;
                }, ariaDescribedBy: 'invoiceError', ariaInvalid: isError, value: invoice })),
        React.createElement("div", { style: { marginTop: '-12px' } },
            React.createElement("div", { className: cx(styles.controlWidth, styles.controlMarginTop) },
                React.createElement(Footnote, { text: getValueByTitle('invoice-number-note') }))),
        isError && (React.createElement("div", { id: 'invoiceError', className: utils.error }, getValueByTitle('required')))));
};
export var VinSelectionByVehicle = function (_a) {
    var theVin = _a.theVin, setVin = _a.setVin, validate = _a.validate, getValueByTitle = _a.getValueByTitle, title = _a.title;
    var _b = useState(false), other = _b[0], setOther = _b[1];
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var vehicles = useContext(PointsAssistContext)[0].vehicles;
    useEffect(function () {
        setOther(!vehicles.find(function (_a) {
            var vin = _a.vin;
            return vin == theVin;
        }));
    }, []);
    var handleChange = function (evnt) {
        var id = evnt.target.id;
        if (id === 'other') {
            setOther(true);
        }
        else {
            setOther(false);
            setVin(id);
        }
    };
    var isError = validate && !((theVin === null || theVin === void 0 ? void 0 : theVin.length) >= 8);
    return (React.createElement("div", null,
        React.createElement("h3", { className: styles.whichVehicle }, getValueByTitle(title)),
        React.createElement("div", { className: styles.radioGroup, onChange: handleChange },
            vehicles.map(function (_a) {
                var vin = _a.vin, vehicleType = _a.vehicleType, nickName = _a.nickName;
                return (React.createElement("div", { key: vin },
                    React.createElement(RadioItem, { name: 'vehicles', key: vin, id: vin, value: vehicleType + (nickName ? "- (".concat(nickName, ")") : ''), checked: vin == theVin })));
            }),
            other && React.createElement(RadioItem, { name: 'vehicles', key: 'other', id: 'other', value: 'Other', checked: other }),
            !other && React.createElement(RadioItem, { name: 'vehicles', key: 'other', id: 'other', value: 'Other' })),
        other && (React.createElement("div", null,
            React.createElement(InputWithLabel, { id: 'vinInput', label: getValueByTitle('vin-input'), minLength: 8, maxLength: 8, onChange: function (evnt) { var _a; return setVin((_a = evnt.target.value) === null || _a === void 0 ? void 0 : _a.toUpperCase().replace(/[^A-Z0-9]/g, '')); }, defaultValue: theVin || '', ariaDescribedBy: 'vinError', ariaInvalid: isError }))),
        isError && (React.createElement("div", { id: 'vinError', className: utils.error }, getValueByTitle('required')))));
};
export var PurchaseDateSelector = function (_a) {
    var purchaseDate = _a.purchaseDate, setPurchaseDate = _a.setPurchaseDate, validate = _a.validate, getValueByTitle = _a.getValueByTitle;
    var isError = validate && !purchaseDate;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", null,
            React.createElement(DatePickerWithLabel, { label: getValueByTitle('date-of-sale'), onChange: function (date) {
                    setPurchaseDate(date);
                    setTimeout(function () { return document.getElementById('purchaseDate').focus(); }, 10);
                }, id: 'purchaseDate', selectedDate: purchaseDate, className: cx(styles.controlWidth, styles.controlMarginTop), "aria-describedby": 'dateError', "aria-invalid": isError })),
        React.createElement("div", { style: { marginTop: '-22px' } },
            React.createElement("div", { className: cx(styles.controlWidth, styles.controlMarginTop) },
                React.createElement(Footnote, { showIcon: true, text: getValueByTitle('date-of-sale-note') }))),
        isError && (React.createElement("div", { id: 'dateError', className: utils.error }, getValueByTitle('required')))));
};
export var ParamEditor = function (_a) {
    var title = _a.title, value = _a.value, children = _a.children;
    var _b = useState(false), editing = _b[0], setEditing = _b[1];
    return (React.createElement("dl", { "aria-labelledby": "".concat(title, "-label"), role: 'group' },
        React.createElement("dt", null, !editing && (React.createElement(React.Fragment, null,
            React.createElement("div", { id: "".concat(title, "-label") }, title),
            React.createElement("a", { onClick: function () { return setEditing(true); }, onKeyPress: function (e) { return e.key === 'Enter' && setEditing(true); }, tabIndex: 0 }, "Edit")))),
        editing ? (React.createElement("dd", null, children)) : (React.createElement("dd", null,
            React.createElement("span", null, value)))));
};
