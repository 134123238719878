import { isMobile } from '../mobile-bridge/mobile-bridge';
import ServiceHandler from '../service-handler';
var authService = ServiceHandler.AuthenticationService;
export var verifyAuthToken = function () {
    if (authService.getCatBundle() && new Date().getTime() / 1000 > JSON.parse(atob(authService.getCatBundle().access_token.split('.')[1])).exp) {
        if (isMobile()) {
            return true;
        }
        else {
            window['fma'].model.config.redirectUrl = window.location.href;
            authService.login();
            return true;
        }
    }
    return true;
};
