export var SERVICE_CODE;
(function (SERVICE_CODE) {
    SERVICE_CODE[SERVICE_CODE["TRANSACTION_SERVICE"] = 1] = "TRANSACTION_SERVICE";
    SERVICE_CODE[SERVICE_CODE["POINTS_SERVICE"] = 2] = "POINTS_SERVICE";
    SERVICE_CODE[SERVICE_CODE["MESSAGE_SERVICE"] = 3] = "MESSAGE_SERVICE";
    SERVICE_CODE[SERVICE_CODE["USER_SERVICE"] = 4] = "USER_SERVICE";
    SERVICE_CODE[SERVICE_CODE["VEHICLES_SERVICE"] = 5] = "VEHICLES_SERVICE";
    SERVICE_CODE[SERVICE_CODE["DEALER_LOOKUP"] = 6] = "DEALER_LOOKUP";
    SERVICE_CODE[SERVICE_CODE["REWARDS_HISTORY_SERVICE"] = 7] = "REWARDS_HISTORY_SERVICE";
    SERVICE_CODE[SERVICE_CODE["CATALOG_SERVICE"] = 8] = "CATALOG_SERVICE";
    SERVICE_CODE[SERVICE_CODE["CREDIT_CARD_PREFILL"] = 9] = "CREDIT_CARD_PREFILL";
    SERVICE_CODE[SERVICE_CODE["CREDIT_CARD_ELIGIBILITY"] = 10] = "CREDIT_CARD_ELIGIBILITY";
    SERVICE_CODE[SERVICE_CODE["OFFERS_SERVICE"] = 11] = "OFFERS_SERVICE";
    SERVICE_CODE[SERVICE_CODE["POINTS_ELIGIBLE_SERVICE"] = 12] = "POINTS_ELIGIBLE_SERVICE";
})(SERVICE_CODE || (SERVICE_CODE = {}));
