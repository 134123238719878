export var TYPES;
(function (TYPES) {
    TYPES["SERVICE"] = "service";
    TYPES["SALE"] = "sale";
    TYPES["REVIEW"] = "review";
    TYPES["INITIAL"] = "initial";
})(TYPES || (TYPES = {}));
var pointsAssistRouter = function (code, type, sequence, redirectLookup) {
    var redirectTest = redirectLookup === null || redirectLookup === void 0 ? void 0 : redirectLookup.redirectPages.find(function (c) { return c.errorCode === code; });
    if (redirectTest)
        return redirectTest[type][sequence];
    return '';
};
export default pointsAssistRouter;
