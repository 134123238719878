import React from 'react';
function AccordionPanelWrapper(_a) {
    var children = _a.children, active = _a.active, setPanel = _a.setPanel;
    return (React.createElement("div", null, children.map(function (panel) {
        return React.cloneElement(panel, {
            key: panel.props.id,
            setPanel: setPanel,
            active: active === panel.props.id,
        });
    })));
}
export default AccordionPanelWrapper;
