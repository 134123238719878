import React from 'react';
import { DynamicTileContextProvider } from './dynamic-tiles-context';
import { PointsHeroContextProvider } from './points-hero-context';
import { APIHelperContextProvider } from './api-helper-context';
import { CountryEligibilityContextProvider } from './country-eligibilty-context';
import { ParticipatingDealerContextProvider } from './paricipating-dealer-context';
import { CatalogContextProvider } from './catalog-context';
import { OffersContextProvider } from './offers-context';
import { UserProfileContextProvider } from './user-profile-context';
import { RenewAuthTokenContextProvider } from './renew-auth-token-context';
export var ContextProviders = function (_a) {
    var children = _a.children;
    return (React.createElement(APIHelperContextProvider, null,
        React.createElement(RenewAuthTokenContextProvider, null,
            React.createElement(PointsHeroContextProvider, null,
                React.createElement(UserProfileContextProvider, null,
                    React.createElement(DynamicTileContextProvider, null,
                        React.createElement(CountryEligibilityContextProvider, null,
                            React.createElement(ParticipatingDealerContextProvider, null,
                                React.createElement(CatalogContextProvider, null,
                                    React.createElement(OffersContextProvider, null, children))))))))));
};
