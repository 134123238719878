import React, { useContext, useState } from 'react';
import CatalogService from '../services/catalog-service/catalog-service';
import { APIHelperContext } from './api-helper-context';
export var CatalogContext = React.createContext({
    payload: null,
});
export var CatalogContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), rewards = _b[0], setRewards = _b[1];
    var apiHelper = useContext(APIHelperContext)[0];
    var refreshRewards = function () {
        var catalogService = new CatalogService();
        catalogService.request(apiHelper).then(function (rewards) {
            setRewards(rewards);
        });
    };
    return React.createElement(CatalogContext.Provider, { value: [rewards, setRewards, refreshRewards] }, children);
};
