import React from 'react';
import cx from '../../../utils/classnames';
import styles from './combo.module.scss';
function ComboElement(_a) {
    var _b;
    var id = _a.id, title = _a.title, desc = _a.desc, active = _a.active, handleClick = _a.handleClick;
    return (React.createElement("li", { key: id, className: cx(styles.comboElement, (_b = {}, _b[styles.active] = active, _b)), onMouseDown: function (evnt) { return handleClick(evnt, id); }, tabIndex: 0 },
        React.createElement("div", { className: styles.title }, title),
        desc));
}
export default ComboElement;
