import React, { useContext, useEffect, useState } from 'react';
import ServiceHandler from '../services/service-handler';
import UserService from '../services/user-service/user-service';
import { APIHelperContext } from './api-helper-context';
export var UserProfileContext = React.createContext({ userProfile: null });
export var UserProfileContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), userProfile = _b[0], setUserProfile = _b[1];
    var _c = useState(null), time = _c[0], setTime = _c[1];
    var apiHelper = useContext(APIHelperContext)[0];
    useEffect(function () {
        if (ServiceHandler.AuthenticationService.getIsAuthenticated()) {
            refreshUserProfile();
        }
    }, []);
    function refreshUserProfile() {
        var userService = new UserService();
        userService.request(apiHelper).then(function (response) {
            setUserProfile(response.profile);
            setTime(response.lastRequested);
        });
    }
    return React.createElement(UserProfileContext.Provider, { value: [{ userProfile: userProfile, time: time, refreshUserProfile: refreshUserProfile }] }, children);
};
