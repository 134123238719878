export var LPS_API_PATH = {
    TRANSACTIONS: '/api/rewards-account-info/v2/customer/transactions',
    POINTS_BALANCES: '/api/rewards/v2/customer/points',
    MESSAGES: '/api/messagecenter/v3/messages',
    USERS: '/api/users',
    VEHICLES: '/api/users/v2/userWithVehicles',
    DEALER: '/api/dealersearch/v3/dealer',
    GET_CATALOG: '/api/reward-catalog/v1/customer/catalog',
    ORDER_STATUS: '/api/reward-catalog/v1/customer/order',
    ORDER_SUBMIT: '/api/reward-catalog/v1/order',
    DEALER_LOOKUP: '/api/member-assist/v1/dealers',
    POINTS_ASSIST_SERVICE: '/api/member-assist/v1/repair/points',
    POINTS_ASSIST_SALE: '/api/member-assist/v1/sale/points',
    GET_OFFERS: '/api/private-offers/v2/customer/offers',
    RENEW_AUTH_TOKEN: '/api/token/v2/cat-with-refresh-token',
    CLAIM_POINTS: '/api/rewards/customer-activity/v1/customer/activities/{offer}',
};
export var FPS_API_PATH = {
    EXTERNALREF: '/fps/api/externalref/set',
    PERSONALIZATION: '/fps/api/personalization_1_8/get',
};
export var DEFAULT_LANGUAGE_CODE = 'en';
export var DEFAULT_COUNTRY_CODE = 'ca';
export var DEFAULT_REGION_CODE = 'ca';
export var LANGUAGE_CODE_EN_CA = "en-".concat(DEFAULT_REGION_CODE);
export var LANGUAGE_CODE_FR_CA = "fr-".concat(DEFAULT_REGION_CODE);
export var SELECTED_VEHICLE_VIN_KEY = 'CX_SELECTED_VEHICLE_VIN';
export var POST_SUCCESS_MESSAGE = 'success';
export var POST_SUCCESS_ERROR_CODE = 'SUCCESS';
export var COUPON_TYPE = 'LARCouponType';
export var SD_AVAILABLE = 'isSDAvailable';
export var REWARD_TYPE = 'LARRewardType';
export var REWARD_REDEEM_LOW_ERROR = '[TRN_COUPONS_LEVEL_TOO_LOW]';
export var fordVideoFR = {
    playerId: 'rkZxOrQ1M',
    accountId: '2270581146001',
    videoId: '6331630146112',
};
export var fordVideoEN = {
    playerId: 'rkZxOrQ1M',
    accountId: '2270581146001',
    videoId: '6331630725112',
};
export var larVideoFR = {
    playerId: 'SJWKuuXJM',
    accountId: '4103911217001',
    videoId: '6329422783112',
};
export var larVideoEN = {
    playerId: 'SJWKuuXJM',
    accountId: '4103911217001',
    videoId: '6329420623112',
};
