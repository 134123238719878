import React, { useContext, useEffect } from 'react';
import { Route } from 'react-router';
import { Switch, useHistory } from 'react-router-dom';
var FordpassRewardsView = React.lazy(function () { return import('./views-fpr/fordpass-rewards-view/fordpass-rewards-view'); });
var FprLandingView = React.lazy(function () { return import('./views-fpr/landing-view/landing-view'); });
var HowToEarnView = React.lazy(function () { return import('./views-fpr/how-to-earn-view/how-to-earn-view'); });
var HowToRedeemView = React.lazy(function () { return import('./views-fpr/how-to-redeem-view/how-to-redeem-view'); });
var StepsView = React.lazy(function () { return import('./views-fpr/steps-view/steps-view'); });
var DynamicTileTestView = React.lazy(function () { return import('./components/Internal-user/dynamic-tile-test/dynamic-tile-test'); });
var RewardsHistoryView = React.lazy(function () { return import('./views-common/rewards-history-view/rewards-history-view'); });
var FaqView = React.lazy(function () { return import('./views-common/faq-view/faq-view'); });
var MaintenanceBreakView = React.lazy(function () { return import('./views-common/maintenance-break-view/maintenance-break-view'); });
var ParticipatingDealersView = React.lazy(function () { return import('./views-common/participating-dealers-view/participating-dealers-view'); });
var RewardsDetailView = React.lazy(function () { return import('./views-common/surprise-and-delight-view/sd-rewards-details-view/sd-rewards-detail-view'); });
var RewardsErrorView = React.lazy(function () { return import('./views-common/sd-cat-static-pages/error-pages/error-page-view'); });
var RewardsConfirmationView = React.lazy(function () { return import('./views-common/sd-cat-static-pages/confirmation-page/sd-cat-confirmation-view'); });
import { AuthorizedRoute } from './components/common/authorized-route/authorized-route';
import { RedirectRoute } from './components/common/authorized-route/redirect-route';
import { InternalUserRoute } from './components/Internal-user/internal-user-route/internal-user-route';
import { useAmplitude } from './hooks/use-amplitude';
import { isMobile } from './services/mobile-bridge/mobile-bridge';
import ServiceHandler from './services/service-handler';
import { RenewAuthTokenContext } from './context/renew-auth-token-context';
import PointsAssistView from './views-common/points-assist-view/points-assist-view';
import PointsAssistReviewView from './views-common/points-assist-view/points-assist-review-view';
import { PointsAssistContextProvider } from './context/points-assist-context';
import { SurpriseDelightContextProvider } from './context/surprise-delight-context';
export var getAmplitudeScreenTitle = function (history) {
    var _a;
    return (_a = history.location) === null || _a === void 0 ? void 0 : _a.pathname.replace(/(claim-points)\/.+/, '$1').replace(/^rewards-/, '').replace(/rewards/, '').replace(/\W+/g, ' ').replace(/(lar|fpr) sd campaigns /, 'campaign ').trim().toLowerCase();
};
export var sanatizedLocationSearch = function (params) {
    if (params === void 0) { params = ''; }
    var searchParams = new URLSearchParams(window.location.search);
    var result = '';
    searchParams.forEach(function (value, key) {
        if (['brand', 'language', 'country', 'hack', 'stealth'].includes(key))
            result += "&".concat(key, "=").concat(value);
    });
    if (params)
        result += "&".concat(params);
    return result.substr(1) ? '?' + result.substr(1) : '';
};
var Routes = function () {
    var logAmplitudeEvent = useAmplitude()[0];
    var history = useHistory();
    var renewAuthToken = useContext(RenewAuthTokenContext)[0].renewAuthToken;
    var authService = ServiceHandler.AuthenticationService;
    useEffect(function () {
        if (!window['glistening']) {
            trackPageView(); // To track the first pageview upon load
            window['glistening'] = history.listen(function () { return window.setTimeout(trackPageView, 200); });
        }
        // To track the subsequent pageviews
    }, [history]);
    var trackPageView = function () {
        var _a;
        var params = new URLSearchParams(window.location.search);
        var title = getAmplitudeScreenTitle(history)
            .replace(/(campaign|catalog|collection).*?(error|confirmation)?$/, '$1 $2')
            .trim();
        logAmplitudeEvent("".concat(title || 'dashboard', " page viewed"), {
            rewardCode: (_a = params.get('rewardCode')) === null || _a === void 0 ? void 0 : _a.toLocaleLowerCase(),
        });
        if (authService.getCatBundle() && new Date().getTime() / 1000 > JSON.parse(atob(authService.getCatBundle().access_token.split('.')[1])).exp) {
            if (isMobile()) {
                renewAuthToken();
            }
        }
    };
    var EntryRoute = isMobile() ? Route : RedirectRoute;
    return (React.createElement(Switch, null,
        React.createElement(EntryRoute, { path: '/', exact: true, render: function () { return React.createElement(FordpassRewardsView, null); }, unauth: '/overview', auth: '/dashboard' }),
        React.createElement(Route, { path: '/overview', exact: true, render: function () { return React.createElement(FprLandingView, null); } }),
        React.createElement(Route, { path: '/faqs', exact: true, render: function () { return React.createElement(FaqView, null); } }),
        React.createElement(Route, { path: '/maintenance-break', exact: true, render: function () { return React.createElement(MaintenanceBreakView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/dashboard', exact: true, render: function () { return React.createElement(FordpassRewardsView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/rewards-history', exact: true, render: function () { return React.createElement(RewardsHistoryView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/steps-to-redeem', exact: true, render: function () { return React.createElement(StepsView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/participating-dealers', exact: true, render: function () { return React.createElement(ParticipatingDealersView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/faqs/participating-dealers', exact: true, render: function () { return React.createElement(ParticipatingDealersView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/fpr-sd-campaigns/SD_ACTIVITIES/reward', exact: true, render: function () { return React.createElement(RewardsDetailView, null); }, context: SurpriseDelightContextProvider }),
        React.createElement(AuthorizedRoute, { path: '/fpr-sd-campaigns/SD_ACTIVITIES/reward/error', exact: true, render: function () { return React.createElement(RewardsErrorView, null); } }),
        React.createElement(AuthorizedRoute, { path: '/fpr-sd-campaigns/SD_ACTIVITIES/reward/confirmation', exact: true, render: function () { return React.createElement(RewardsConfirmationView, null); }, context: SurpriseDelightContextProvider }),
        React.createElement(Route, { path: '/how-to-earn', exact: true, render: function () { return React.createElement(HowToEarnView, null); } }),
        React.createElement(Route, { path: '/how-to-redeem', exact: true, render: function () { return React.createElement(HowToRedeemView, null); } }),
        React.createElement(InternalUserRoute, { path: '/dynamic-tile-test', exact: true, render: function () { return React.createElement(DynamicTileTestView, null); } }),
        React.createElement(RedirectRoute, { path: '/offers', exact: true, unauth: '/dashboard', auth: '/dashboard' }),
        React.createElement(RedirectRoute, { path: '/landing', exact: true, unauth: '/overview', auth: '/overview' }),
        React.createElement(RedirectRoute, { path: '/earn', exact: true, unauth: '/how-to-earn', auth: '/how-to-earn' }),
        React.createElement(RedirectRoute, { path: '/redeem', exact: true, unauth: '/how-to-redeem', auth: '/how-to-redeem' }),
        React.createElement(RedirectRoute, { path: '/steps', exact: true, unauth: '/steps-to-redeem', auth: '/steps-to-redeem' }),
        React.createElement(RedirectRoute, { path: '/faqs/participating-dealers', exact: true, auth: '/participating-dealers', unauth: '/participating-dealers' }),
        React.createElement(AuthorizedRoute, { path: '/rewards-history/points-assist', exact: true, render: function () { return React.createElement(PointsAssistView, null); }, context: PointsAssistContextProvider }),
        React.createElement(AuthorizedRoute, { path: '/rewards-history/points-assist-review', exact: true, render: function () { return React.createElement(PointsAssistReviewView, null); }, context: PointsAssistContextProvider }),
        React.createElement(Route, { path: '*', 
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            render: function () {
                window.location.href = "https://".concat(ServiceHandler.ConfigService.LANGUAGE_CODE == 'en' ? 'www' : ServiceHandler.ConfigService.LANGUAGE_CODE, ".ford.ca/404");
                return null;
            } })));
};
export default Routes;
