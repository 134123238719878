import React, { useEffect, useState, useContext } from 'react';
import { BrowserRouter as Router } from 'react-router-dom';
//////////////////////
//////////////////////////////////////////////////////////////////////
/////////
import Routes from './routes-fpr'; // eslint-disable-line no-redeclare
//////////
import ServiceHandler from './services/service-handler';
import Layout from './layout/layout';
import { ScrollToTop } from './components/common/scroll-to-top/scroll-to-top';
import { ContextProviders } from './context/context-providers';
import TermsConditionsLink from './components/sections/terms-conditions-link-view/terms-conditions-link-view';
import amplitude from 'amplitude-js';
import { NavBar } from './components/sections/nav-bar/nav-bar';
import { isMobile } from './services/mobile-bridge/mobile-bridge';
import UserService from './services/user-service/user-service';
import { APIHelperContext } from './context/api-helper-context';
var App = function () {
    var cfg = ServiceHandler.ConfigService;
    var _a = useState(false), appLoaded = _a[0], setAppLoaded = _a[1];
    var authService = ServiceHandler.AuthenticationService;
    var apiHelper = useContext(APIHelperContext)[0];
    useEffect(function () {
        amplitude.getInstance().init(cfg.AMPLITUDE_API_KEY);
        amplitude.getInstance().setUserProperties({
            loyaltyLanguage: cfg.LANGUAGE_CODE,
            loyaltyCountry: cfg.PROGRAM_COUNTRY,
            loyaltyBrand: cfg.BRAND_ID,
            loyaltyMobile: isMobile(),
        });
        ServiceHandler.AuthenticationService.onIsAuthenticated().then(function () {
            var _a, _b;
            setAppLoaded(true);
            if (isMobile()) {
                var userService = new UserService();
                userService.request(apiHelper).then(function (_a) {
                    var profile = _a.profile;
                    return amplitude.getInstance().setUserId(profile.userGuid.toLowerCase());
                });
            }
            else {
                amplitude.getInstance().setUserId((_b = (_a = window['fma']) === null || _a === void 0 ? void 0 : _a.CATBundle) === null || _b === void 0 ? void 0 : _b.userId.toLowerCase());
            }
        });
    }, [authService]);
    useEffect(function () {
        var _a;
        if ((_a = ServiceHandler.AuthenticationService.getCatBundle()) === null || _a === void 0 ? void 0 : _a.userId)
            amplitude.getInstance().setUserId(ServiceHandler.AuthenticationService.getCatBundle().userId.toLowerCase());
    }, [ServiceHandler.AuthenticationService.getCatBundle()]);
    return (React.createElement(React.Fragment, null, appLoaded === true && (React.createElement(React.Suspense, { fallback: React.createElement("div", null) },
        React.createElement(Router, { basename: cfg.APP_BASEPATH },
            React.createElement(ContextProviders, null,
                React.createElement(ScrollToTop, null),
                React.createElement(Layout, null,
                    React.createElement(NavBar, null),
                    React.createElement(Routes, null)),
                React.createElement(TermsConditionsLink, null)))))));
};
export default App;
