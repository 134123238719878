import React from 'react';
import cx from '../../../utils/classnames';
import styles from './input-with-label.module.scss';
export var InputWithLabel = function (_a) {
    var id = _a.id, defaultValue = _a.defaultValue, _b = _a.value, value = _b === void 0 ? '' : _b, label = _a.label, className = _a.className, onChange = _a.onChange, onBlur = _a.onBlur, onFocus = _a.onFocus, onClick = _a.onClick, onKeyDown = _a.onKeyDown, minLength = _a.minLength, maxLength = _a.maxLength, disabled = _a.disabled, children = _a.children, labelClassName = _a.labelClassName, ariaDescribedBy = _a.ariaDescribedBy, ariaInvalid = _a.ariaInvalid;
    return (React.createElement("div", { className: styles.inputGroup },
        React.createElement("input", { value: value || undefined, "aria-label": label, id: id, disabled: disabled, type: 'text', className: cx(styles.inputControl, className), placeholder: '*', pattern: '.*\\S+.*', onChange: onChange, onBlur: onBlur, onFocus: onFocus, onClick: onClick, onKeyDown: onKeyDown, minLength: minLength, maxLength: maxLength, defaultValue: defaultValue, "aria-describedby": ariaDescribedBy, "aria-invalid": ariaInvalid }),
        React.createElement("label", { htmlFor: id, className: cx(styles.inputLabel, labelClassName) }, label),
        children));
};
