/* eslint-disable no-redeclare */
import React, { useEffect, useState } from 'react';
import TabBar from '../../common/tabbar/tabbar';
import Tab from '../../common/tabbar/tab';
import { useHistory } from 'react-router';
import { useContent } from '../../../hooks/use-content';
import styles from './nav-bar.module.scss';
import { getAmplitudeScreenTitle, sanatizedLocationSearch } from '../../../routes-fpr';
import { isMobile } from '../../../services/mobile-bridge/mobile-bridge';
import { CountrySelector, CountrySelectorSpeedBump } from '../country-selector/country-selector';
import cx from '../../../utils/classnames';
import PointsExpire from '../../../views-common/points-expiration/points-expire';
import GenericBanner from '../../common/generic-banner/generic-banner';
var NAV_BAR_TAB_STATES = {
    REWARDS_DASHBOARD_TAB: '/dashboard',
    TIERS_AND_MILESTONES_TAB: '/tiers-and-milestones',
    HOW_TO_EARN_TAB: '/how-to-earn',
    HOW_TO_REDEEM_TAB: '/how-to-redeem',
    OFFERS_TAB: '/offers-and-promotions',
    VISA_TAB: '/credit-card',
    COLLECTION_TAB: '/collection',
};
export var NavBar = function () {
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _a = useContent('nav-bar'), content = _a[0], getValueByTitle = _a[1];
    var history = useHistory();
    var _b = useState(''), activeTab = _b[0], setActiveTab = _b[1];
    var _c = useState(false), showCountrySpeedbump = _c[0], setShowCountrySpeedbump = _c[1];
    useEffect(function () {
        setActiveTab(Object.values(NAV_BAR_TAB_STATES).find(function (val) { return window.location.pathname.includes(val); }));
    });
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.navBar },
            React.createElement(TabBar, { setTab: function (id) {
                    setActiveTab(id);
                    history.push(id + sanatizedLocationSearch());
                }, active: activeTab, className: styles.tabBar, ariaLabel: 'navigation tabbar', mobileTitle: getValueByTitle('mobile-title') },
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.REWARDS_DASHBOARD_TAB, active: true }, getValueByTitle('rewards-dashboard-tab')),
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.HOW_TO_EARN_TAB }, getValueByTitle('how-to-earn-tab')),
                React.createElement(Tab, { className: styles.tab, id: NAV_BAR_TAB_STATES.HOW_TO_REDEEM_TAB }, getValueByTitle('how-to-redeem-tab')),
                isMobile() && (React.createElement(Tab, { className: cx(styles.tab, styles.noPad), id: '' },
                    React.createElement(CountrySelector, { setOverlay: setShowCountrySpeedbump }))))),
        isMobile() && showCountrySpeedbump && React.createElement(CountrySelectorSpeedBump, { setOverlay: setShowCountrySpeedbump }),
        React.createElement("h1", { className: styles.accessiblily, style: { position: 'absolute' } }, getAmplitudeScreenTitle(history)),
        React.createElement(PointsExpire, null),
        React.createElement(GenericBanner, null)));
};
