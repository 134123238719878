import React, { useState, useContext, useEffect } from 'react';
import { useContent } from '../../hooks/use-content';
// Load views for different brands
//////////////////////
//////////////////////////////////////////////////////////////////////
/////////
import './fpr-points-expire.scss'; // eslint-disable-line no-redeclare
//////////
import { PointsHeroContext } from '../../context/points-hero-context';
import ServiceHandler from '../../services/service-handler';
import { prettyDate } from '../../utils/points.utils';
import { CtaLink } from '../../components/common/cta/cta';
var PointsExpire = function () {
    var EXPIRE_BANNER = 'rewardsPointExpiredBannerOpen';
    var _a = useState(false), show = _a[0], setShow = _a[1];
    var pointExpirationDate = useContext(PointsHeroContext)[0].pointExpirationDate;
    var expirationDate = new Date(pointExpirationDate);
    var getValueByTitle = useContent('points-history')[0].getValueByTitle;
    var cfg = ServiceHandler.ConfigService;
    var setShowDialog = function () {
        setShow(false);
        sessionStorage.setItem(EXPIRE_BANNER, 'true');
    };
    useEffect(function () {
        if (pointExpirationDate) {
            var today = new Date();
            //calculate time difference
            var time_difference = (expirationDate === null || expirationDate === void 0 ? void 0 : expirationDate.getTime()) - (today === null || today === void 0 ? void 0 : today.getTime());
            //calculate days difference by dividing total milliseconds in a day
            var days_difference = time_difference / (1000 * 60 * 60 * 24);
            if (days_difference <= 90) {
                setShow(true);
            }
        }
    }, [pointExpirationDate]);
    var expireText1 = getValueByTitle('expire_text-1');
    var expireText2 = getValueByTitle('expire_text-2');
    {
        return show ? (React.createElement("div", { className: 'pointsExpireContainer' },
            React.createElement("div", { className: 'expireTextContainer' },
                React.createElement("div", { className: 'footnoteIcon' }),
                React.createElement("div", { className: 'expireText' }, "".concat(expireText1, " ").concat(prettyDate(expirationDate), ". ").concat(expireText2),
                    React.createElement(CtaLink, { className: 'redeemPoints', link: '/how-to-redeem', arrow: cfg.isFPR ? false : true, label: getValueByTitle('Redeem Points') }))),
            React.createElement("button", { onClick: function () { return setShowDialog(); }, className: 'closeIcon' },
                React.createElement("div", null)))) : (React.createElement(React.Fragment, null));
    }
};
export default PointsExpire;
