import React from 'react';
import ServiceHandler from '../../../services/service-handler';
import cx from '../../../utils/classnames';
import styles from './accordion-panel.module.scss';
import common from '../../../utils/common.module.scss';
export function AccordionPanel(_a) {
    var header = _a.header, className = _a.className, children = _a.children, id = _a.id, setPanel = _a.setPanel, open = _a.open;
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.accorion },
            React.createElement("div", { className: cx(styles.header, className) },
                React.createElement("button", { className: cx(styles.headerButton, styles[open ? 'open' : 'close'], common.accordianFont, common[ServiceHandler.ConfigService.LANGUAGE_CODE]), onClick: function () {
                        open ? setPanel('') : setPanel(id);
                    }, id: id, "aria-expanded": open },
                    header,
                    React.createElement("span", { className: styles.toggle })),
                open && React.createElement("div", { className: styles.panel }, children)))));
}
