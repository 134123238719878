import ServiceHandler from '../services/service-handler';
export var getPoints = function (transaction) {
    return transaction.points.toLocaleString(ServiceHandler.ConfigService.LANGUAGE_CODE);
};
export var prettyDate = function (date) {
    return date.toLocaleDateString(ServiceHandler.ConfigService.LANGUAGE_CODE, { year: 'numeric', month: 'long', day: 'numeric' });
};
export var getTransactionDescription = function (transaction) {
    return transaction.partnerCategory === 'D' ? transaction.partnerName : transaction.pointData[0].description;
};
