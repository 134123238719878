import ServiceHandler from '../service-handler';
import HttpService from '../http-service/http-service';
// NOTE: copied from global-owner
var ContentService = /** @class */ (function () {
    function ContentService() {
        this.transformContentResponse = function (response) {
            return response.data;
        };
        this.transformExperienceResponse = function (response) {
            return response.data;
        };
        this.transformConfigResponse = function (response) {
            return response.data;
        };
    }
    ContentService.prototype.getContent = function (name) {
        var cfg = ServiceHandler.ConfigService;
        var url = "".concat(cfg.AEM_BASE_URL, "/content/dam/loyalty/").concat(cfg.BRAND_NAME, "/").concat(cfg.COUNTRY_CODE, "/").concat(cfg.LANGUAGE_COUNTRY_CODE, "/content-fragments/").concat(name, ".loyalty-model.json");
        var response = HttpService.get(url, true);
        return response.then(this.transformContentResponse).catch(function (error) {
            throw error;
        });
    };
    ContentService.prototype.getExperience = function (name) {
        var cfg = ServiceHandler.ConfigService;
        var url = "".concat(cfg.AEM_BASE_URL, "/content/experience-fragments/loyalty/").concat(cfg.BRAND_NAME, "/").concat(cfg.COUNTRY_CODE, "/").concat(cfg.LANGUAGE_COUNTRY_CODE, "/").concat(name, "/master.model.json");
        var response = HttpService.get(url, true);
        return response.then(this.transformExperienceResponse);
    };
    ContentService.prototype.getConfig = function (name) {
        var cfg = ServiceHandler.ConfigService;
        var url = "".concat(cfg.AEM_BASE_URL, "/content/dam/loyalty/config/").concat(name, ".loyalty-model.json");
        var response = HttpService.get(url, true);
        return response.then(this.transformConfigResponse).catch(function (error) {
            throw error;
        });
    };
    return ContentService;
}());
export { ContentService };
var contentService = new ContentService();
export default contentService;
