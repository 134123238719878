var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState, useContext } from 'react';
import utils from '../../utils/utils.module.scss';
import common from '../../utils/common.module.scss';
import { useContent } from '../../hooks/use-content';
import Breadcrumbs from '../../components/common/breadcrumbs/breadcrumbs';
import { APIHelperContext } from '../../context/api-helper-context';
import cx from '../../utils/classnames';
import { ActivityIndicator } from '../../components/common/activity-indicator/activity-indicator';
import { AccordionPanel } from '../../components/common/accordion-panel/accordion-panel';
import AccordionPanelWrapper from '../../components/common/accordion-panel/accordion-panel-wrapper';
import styles from './points-assist-view.module.scss';
import { CtaLink, CtaButton } from '../../components/common/cta/cta';
import { isMobile } from '../../services/mobile-bridge/mobile-bridge';
import { DealerLookup, InvoiceInput, VinSelectionByVehicle, PurchaseDateSelector, getId, getDesc, submitForReview, SUBMIT_REPAIR, SUBMIT_SALE, } from './points-assist-helper-components';
import { useHistory } from 'react-router-dom';
import ServiceHandler from '../../services/service-handler';
var PointsAssistView = function () {
    var _a;
    var _b = useContent('pointsassist/points-assist')[0], content = _b.content, getValueByTitle = _b.getValueByTitle, getCtaProps = _b.getCtaProps;
    var _c = useContext(APIHelperContext), apiHelper = _c[0], apiFatalHelper = _c[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _d = useState(null), chosenDealer = _d[0], setChosenDealer = _d[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _e = useState(null), purchaseDate = _e[0], setPurchaseDate = _e[1];
    var _f = useState(null), invoiceNumber = _f[0], setInvoiceNumber = _f[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _g = useState(null), vin = _g[0], setVin = _g[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _h = useState(false), validate = _h[0], setValidate = _h[1]; // eslint-disable-line @typescript-eslint/no-unused-vars
    var _j = useState(false), disabled = _j[0], setDisabled = _j[1];
    var _k = useState(''), activePanel = _k[0], setActivePanel = _k[1];
    var history = useHistory();
    var breadcrumbs = [
        {
            name: 'breadcrumb-1',
            url: '/dashboard',
        },
        {
            name: 'breadcrumb-2',
            url: '/rewards-history',
        },
        {
            name: 'breadcrumb-3',
            url: '',
        },
    ];
    var submitParams = {
        history: history,
        chosenDealer: chosenDealer,
        invoiceNumber: invoiceNumber,
        vin: vin,
        purchaseDate: purchaseDate,
        pending: false,
        setDisabled: setDisabled,
    };
    var doSubmit = function (type) {
        setValidate(true);
        setDisabled(true);
        submitForReview(__assign(__assign({}, submitParams), { type: type }));
    };
    return (React.createElement(React.Fragment, null, content ? (React.createElement(React.Fragment, null,
        React.createElement("div", { className: styles.main },
            React.createElement(Breadcrumbs, { breadcrumbs: breadcrumbs, getValueByTitle: getValueByTitle }),
            React.createElement("div", { className: cx(utils.contentCenter90, utils.spacer80, styles.content) },
                React.createElement("div", { className: cx(common.h3, styles.beRewarded) }, getValueByTitle('be-rewarded')),
                React.createElement("h1", { className: cx(common.h1, styles.h1, common.pointsAssistHeading, common[ServiceHandler.ConfigService.LANGUAGE_CODE]) }, getValueByTitle('title')),
                React.createElement("p", { className: cx(styles.description, utils.max700, common.pointsAssistSubText) }, getValueByTitle('description')),
                React.createElement(AccordionPanelWrapper, { setPanel: function (id) { return setActivePanel(id); }, active: activePanel },
                    React.createElement(AccordionPanel, { header: getValueByTitle('vehicle-service'), className: styles.accordianText, id: SUBMIT_REPAIR, open: SUBMIT_REPAIR === activePanel },
                        React.createElement("div", { className: styles.controlWrapper },
                            React.createElement(DealerLookup, { dealer: chosenDealer, setChosenDealer: setChosenDealer, getId: getId, getDesc: getDesc, validate: validate, getValueByTitle: getValueByTitle }),
                            React.createElement("br", null),
                            React.createElement(InvoiceInput, { invoice: invoiceNumber, setInvoiceNumber: setInvoiceNumber, validate: validate, getValueByTitle: getValueByTitle }),
                            React.createElement("br", null),
                            React.createElement(VinSelectionByVehicle, { theVin: vin, setVin: setVin, validate: validate, getValueByTitle: getValueByTitle, title: 'select-vehicle' }),
                            React.createElement("div", { className: styles.cta, onKeyDown: function (e) {
                                    if (e.keyCode == 13)
                                        doSubmit(SUBMIT_REPAIR);
                                } },
                                React.createElement(CtaLink, __assign({}, getCtaProps('submit'), { onClick: function () { return doSubmit(SUBMIT_REPAIR); }, disabled: disabled, arrow: !disabled, allowDblClick: true }))))),
                    React.createElement(AccordionPanel, { header: getValueByTitle('new-purchase'), className: styles.accordianText, id: SUBMIT_SALE, open: SUBMIT_SALE === activePanel },
                        React.createElement("div", { className: styles.controlWrapper },
                            React.createElement(DealerLookup, { dealer: chosenDealer, setChosenDealer: setChosenDealer, getId: getId, getDesc: getDesc, validate: validate, getValueByTitle: getValueByTitle }),
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement(PurchaseDateSelector, { purchaseDate: purchaseDate, setPurchaseDate: setPurchaseDate, validate: validate, getValueByTitle: getValueByTitle }),
                            React.createElement("br", null),
                            React.createElement("br", null),
                            React.createElement(VinSelectionByVehicle, { theVin: vin, setVin: setVin, validate: validate, getValueByTitle: getValueByTitle, title: 'select-vehicle' }),
                            React.createElement("div", { className: styles.cta },
                                React.createElement(CtaLink, __assign({}, getCtaProps('submit'), { onClick: function () { return doSubmit(SUBMIT_SALE); }, onKeyDown: function (e) {
                                        if (e.keyCode == 13)
                                            doSubmit(SUBMIT_SALE);
                                    }, disabled: disabled, arrow: !disabled, allowDblClick: true })))))))),
        React.createElement("div", { className: cx(styles.morePointsSections, (_a = {}, _a[styles.mobileAppButtonWidth] = isMobile(), _a)) },
            React.createElement("div", { className: common.pointsAssistHeading }, getValueByTitle('more-points-heading')),
            React.createElement("p", null, getValueByTitle('more-points-copy')),
            React.createElement(CtaButton, __assign({}, getCtaProps('more-points-cta'), { link: '/how-to-earn' }))))) : (React.createElement(ActivityIndicator, null))));
};
export default PointsAssistView;
