var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import amplitude from 'amplitude-js';
import { useHistory } from 'react-router-dom';
import { getAmplitudeScreenTitle } from '../routes-fpr';
import { isMobile } from '../services/mobile-bridge/mobile-bridge';
import ServiceHandler from '../services/service-handler';
export var useAmplitude = function () {
    var history = useHistory();
    var logAmplitudeEvent = function (name, params) {
        if (name === void 0) { name = 'unknown'; }
        if (params === void 0) { params = {}; }
        amplitude.getInstance().logEvent("rewards ".concat(name).toLowerCase().replace(/\W+/g, ' '), __assign(__assign({}, params), { screenName: getAmplitudeScreenTitle(history), loyaltyExperience: "".concat(ServiceHandler.ConfigService.BRAND_ID, "_").concat(ServiceHandler.ConfigService.PROGRAM_COUNTRY), loyaltyMobile: isMobile() }));
    };
    return [logAmplitudeEvent];
};
