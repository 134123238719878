var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React, { useState } from 'react';
import { FdsChevron } from '../chevron/fds-chevron';
import { Link, useHistory } from 'react-router-dom';
import { getAmplitudeScreenTitle, sanatizedLocationSearch } from '../../../routes-fpr';
import cx from '../../../utils/classnames';
import common from '../../../utils/common.module.scss';
import { useAmplitude } from '../../../hooks/use-amplitude';
import ServiceHandler from '../../../services/service-handler';
import { iOSBridgeAvailable } from '../../../services/mobile-bridge/mobile-bridge';
export var CLICK_ONCE_FLAG = 'FPRrewardsClickOnceFlag';
function ArrowCta(arrowProperties) {
    return (arrowProperties === null || arrowProperties === void 0 ? void 0 : arrowProperties.arrow) ? React.createElement(FdsChevron, { type: arrowProperties.type || 'unfilled', direction: arrowProperties.direction || 'right' }) : React.createElement(React.Fragment, null);
}
export var defaultArrowProps = {
    type: 'unfilled',
    direction: 'right',
    arrow: true,
};
export var CtaLink = function (propsCta) {
    return (React.createElement(Cta, __assign({}, propsCta, { className: cx(common.link, propsCta.className), arrowProperties: { arrow: propsCta.arrow != null ? propsCta.arrow : true } })));
};
export var CtaButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: cx(common.buttonPrimary, propsCta.className) }));
};
export var CtaSecondaryButton = function (propsCta) {
    return React.createElement(Cta, __assign({}, propsCta, { className: cx(common.buttonOutlinePrimary, propsCta.className) }));
};
export var Cta = function (propsCta) {
    var _a;
    var logAmplitudeEvent = useAmplitude()[0];
    var _b = useState(false), pressed = _b[0], setPressed = _b[1];
    var history = useHistory();
    var handleOnClick = function (e) {
        if ((pressed && !propsCta.allowDblClick) || propsCta.disabled) {
            e.preventDefault();
            return;
        }
        logAmplitudeEvent("cta tapped", {
            ctaName: propsCta.id || propsCta.ariaLabel || propsCta.label,
            labelText: propsCta.ariaLabel || propsCta.label,
        });
        if (propsCta.onClick) {
            setPressed(true);
            if (!propsCta.disabled)
                propsCta.onClick();
            var elem = document.activeElement;
            elem.blur();
        }
    };
    var href = (propsCta.href || ServiceHandler.ConfigService.APP_BASEPATH + propsCta.link) + sanatizedLocationSearch(propsCta.search);
    if (!propsCta.href && !propsCta.link)
        href = undefined;
    if (href == 'undefined')
        href = undefined;
    var allowFastLink = !iOSBridgeAvailable() || getAmplitudeScreenTitle(history) !== '';
    return (React.createElement("span", { onClick: handleOnClick, tabIndex: 0, className: cx((_a = {}, _a[common.transparent] = !propsCta.allowDblClick && pressed, _a)) }, !propsCta.href && propsCta.link && allowFastLink ? (React.createElement(Link, { to: {
            pathname: propsCta.link,
            search: sanatizedLocationSearch(propsCta.search),
        }, replace: false, className: propsCta.className, "aria-label": propsCta.ariaLabel || propsCta.label, id: propsCta.id, 
        // TODO look into setting a default value
        target: propsCta.target },
        propsCta.children ? propsCta.children : React.createElement("span", null, propsCta.label),
        React.createElement(ArrowCta, __assign({}, propsCta.arrowProperties)))) : (React.createElement("a", { href: href, className: propsCta.className, "aria-label": propsCta.ariaLabel || propsCta.label, id: propsCta.id, target: propsCta.target },
        propsCta.children ? propsCta.children : React.createElement("span", null, propsCta.label),
        React.createElement(ArrowCta, __assign({}, propsCta.arrowProperties))))));
};
