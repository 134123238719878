import React, { useState } from 'react';
export var DynamicTilesContext = React.createContext({
    earn: [],
    redeem: [],
    loaded: false,
});
export var DynamicTileContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState({ earn: [], redeem: [], loaded: false }), dynamicTiles = _b[0], setDynamicTiles = _b[1];
    return React.createElement(DynamicTilesContext.Provider, { value: [dynamicTiles, setDynamicTiles] }, children);
};
