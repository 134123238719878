// NOTE: copied from global-owner
var BrowserAuthenticationService = /** @class */ (function () {
    function BrowserAuthenticationService() {
        var _this = this;
        this.AUTHENTICATED_EVENT = 'fma_authenticated';
        this.UNAUTHENTICATED_EVENT = 'fma_unauthenticated';
        this.win = window;
        this.handleAuthenticatedEvent = null;
        this.handleUnauthenticatedEvent = null;
        this.isAuthenticated = null;
        this.fmaAlreadyAuthenticated = function () {
            var _a;
            return (_a = _this.win.fma) === null || _a === void 0 ? void 0 : _a.isAuthenticated;
        };
        this.getCatBundle = function () {
            var _a, _b;
            return (_b = (_a = _this.win) === null || _a === void 0 ? void 0 : _a.fma) === null || _b === void 0 ? void 0 : _b.CATBundle;
        };
        this.setCatBundle = function () {
            //not used in browser
        };
        this.addAuthenticationEventListeners = function (resolve) {
            document.body.addEventListener(_this.AUTHENTICATED_EVENT, (_this.handleAuthenticatedEvent = function () {
                resolve(true);
                _this.removeAuthenticationEventListeners();
            }));
            document.body.addEventListener(_this.UNAUTHENTICATED_EVENT, (_this.handleUnauthenticatedEvent = function () {
                resolve(false);
                _this.removeAuthenticationEventListeners();
            }));
        };
        this.removeAuthenticationEventListeners = function () {
            if (_this.handleAuthenticatedEvent) {
                document.body.removeEventListener(_this.AUTHENTICATED_EVENT, _this.handleAuthenticatedEvent);
            }
            if (_this.handleUnauthenticatedEvent) {
                document.body.removeEventListener(_this.UNAUTHENTICATED_EVENT, _this.handleUnauthenticatedEvent);
            }
        };
        this.login = function () {
            _this.win.fma && _this.win.fma.login();
        };
        this.logout = function () {
            _this.win.fma && _this.win.fma.logout();
        };
        this.onIsAuthenticated = function () {
            return _this.isAuthenticatedPromise;
        };
        this.getIsAuthenticated = function () {
            if (_this.isAuthenticated === null) {
                throw new Error('getIsAuthenticated is being called before the authentication check has completed. This function should only be called in code that has been wrapped inside onIsAuthenticated');
            }
            return _this.isAuthenticated;
        };
        this.isAuthenticatedPromise = new Promise(function (resolve) {
            if (_this.fmaAlreadyAuthenticated()) {
                resolve(true);
            }
            else if (_this.fmaAlreadyAuthenticated() === false) {
                resolve(false);
            }
            else {
                _this.addAuthenticationEventListeners(resolve);
            }
        });
        this.isAuthenticatedPromise.then(function (isAuthenticated) {
            _this.isAuthenticated = isAuthenticated;
        });
    }
    BrowserAuthenticationService.VERSION_STAMP = '%LOYALTYWEBPAGESVERSION%';
    return BrowserAuthenticationService;
}());
export { BrowserAuthenticationService };
