var __awaiter = (this && this.__awaiter) || function (thisArg, _arguments, P, generator) {
    function adopt(value) { return value instanceof P ? value : new P(function (resolve) { resolve(value); }); }
    return new (P || (P = Promise))(function (resolve, reject) {
        function fulfilled(value) { try { step(generator.next(value)); } catch (e) { reject(e); } }
        function rejected(value) { try { step(generator["throw"](value)); } catch (e) { reject(e); } }
        function step(result) { result.done ? resolve(result.value) : adopt(result.value).then(fulfilled, rejected); }
        step((generator = generator.apply(thisArg, _arguments || [])).next());
    });
};
var __generator = (this && this.__generator) || function (thisArg, body) {
    var _ = { label: 0, sent: function() { if (t[0] & 1) throw t[1]; return t[1]; }, trys: [], ops: [] }, f, y, t, g;
    return g = { next: verb(0), "throw": verb(1), "return": verb(2) }, typeof Symbol === "function" && (g[Symbol.iterator] = function() { return this; }), g;
    function verb(n) { return function (v) { return step([n, v]); }; }
    function step(op) {
        if (f) throw new TypeError("Generator is already executing.");
        while (g && (g = 0, op[0] && (_ = 0)), _) try {
            if (f = 1, y && (t = op[0] & 2 ? y["return"] : op[0] ? y["throw"] || ((t = y["return"]) && t.call(y), 0) : y.next) && !(t = t.call(y, op[1])).done) return t;
            if (y = 0, t) op = [op[0] & 2, t.value];
            switch (op[0]) {
                case 0: case 1: t = op; break;
                case 4: _.label++; return { value: op[1], done: false };
                case 5: _.label++; y = op[1]; op = [0]; continue;
                case 7: op = _.ops.pop(); _.trys.pop(); continue;
                default:
                    if (!(t = _.trys, t = t.length > 0 && t[t.length - 1]) && (op[0] === 6 || op[0] === 2)) { _ = 0; continue; }
                    if (op[0] === 3 && (!t || (op[1] > t[0] && op[1] < t[3]))) { _.label = op[1]; break; }
                    if (op[0] === 6 && _.label < t[1]) { _.label = t[1]; t = op; break; }
                    if (t && _.label < t[2]) { _.label = t[2]; _.ops.push(op); break; }
                    if (t[2]) _.ops.pop();
                    _.trys.pop(); continue;
            }
            op = body.call(thisArg, _);
        } catch (e) { op = [6, e]; y = 0; } finally { f = t = 0; }
        if (op[0] & 5) throw op[1]; return { value: op[0] ? op[1] : void 0, done: true };
    }
};
import React, { useState, useEffect, useContext } from 'react';
import PointsBalanceService from '../services/points-balance-service/points-balance-service';
import TransactionsService from '../services/transaction-service/transaction-service';
import { APIHelperContext } from './api-helper-context';
import ServiceHandler from '../services/service-handler';
export var PointsHeroContext = React.createContext({
    totalPoints: null,
    latestTransaction: undefined,
});
export var PointsHeroContextProvider = function (_a) {
    var children = _a.children;
    var _b = useState(null), totalPoints = _b[0], setTotalPoints = _b[1];
    var _c = useState([]), transactions = _c[0], setTransactions = _c[1];
    var _d = useState(), latestTransaction = _d[0], setlatestTransaction = _d[1];
    var _e = useState(null), pointExpirationDate = _e[0], setPointExpirationDate = _e[1];
    var _f = useState(false), noPointsExpiration = _f[0], setNoPointsExpiration = _f[1];
    var tier = useState()[0];
    var apiHelper = useContext(APIHelperContext)[0];
    var reloadPoints = function () { return __awaiter(void 0, void 0, void 0, function () {
        var balanceService;
        return __generator(this, function (_a) {
            switch (_a.label) {
                case 0:
                    balanceService = new PointsBalanceService();
                    return [4 /*yield*/, balanceService.request(apiHelper).then(function (balance) {
                            if (balance) {
                                if (!balance.pointsExpirationDate) {
                                    setNoPointsExpiration(true);
                                }
                                setTotalPoints(balance.points);
                                setPointExpirationDate(balance.pointsExpirationDate);
                            }
                        })];
                case 1:
                    _a.sent();
                    return [2 /*return*/];
            }
        });
    }); };
    useEffect(function () {
        if (totalPoints == null && ServiceHandler.AuthenticationService.getIsAuthenticated()) {
            reloadPoints();
        }
    }, []);
    useEffect(function () {
        if (!latestTransaction && ServiceHandler.AuthenticationService.getIsAuthenticated()) {
            var transactionService = new TransactionsService();
            transactionService.request(apiHelper).then(function (response) {
                var _a;
                if (!((_a = response === null || response === void 0 ? void 0 : response.transactions) === null || _a === void 0 ? void 0 : _a.length))
                    return;
                setTransactions(response.transactions);
                var positiveTransactions = response.transactions.filter(function (transaction) { return transaction.points > 0; });
                if (positiveTransactions.length)
                    setlatestTransaction(positiveTransactions[0]);
            });
        }
    }, []);
    return (React.createElement(PointsHeroContext.Provider, { value: [{ setTotalPoints: setTotalPoints, totalPoints: totalPoints, pointExpirationDate: pointExpirationDate, transactions: transactions, latestTransaction: latestTransaction, tier: tier, reloadPoints: reloadPoints, noPointsExpiration: noPointsExpiration }] }, children));
};
