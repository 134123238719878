import React, { useEffect, useRef, useState } from 'react';
import cx from '../../../utils/classnames';
import styles from './tabbar.module.scss';
import utils from '../../../utils/utils.module.scss';
function TabBar(_a) {
    var _b;
    var _c, _d;
    var children = _a.children, active = _a.active, setTab = _a.setTab, className = _a.className, ariaLabel = _a.ariaLabel, mobileTitle = _a.mobileTitle;
    var _e = useState(false), open = _e[0], setOpen = _e[1];
    var theButton = useRef();
    useEffect(function () {
        if (open) {
            document.addEventListener('mousedown', doClose);
        }
        else {
            document.removeEventListener('mousedown', doClose);
        }
        return function () { return document.removeEventListener('mousedown', doClose); };
    }, [open]);
    var doClose = function () {
        setOpen(false);
        setTimeout(function () { return theButton.current.focus(); }, 10);
    };
    var renderTabbar = function () { return (React.createElement("div", { className: cx(styles.container, className), role: 'tablist', onMouseDown: doClose }, children
        .filter(function (t) { return t; })
        .map(function (tab) {
        return React.cloneElement(tab, {
            key: tab.props.id,
            setTab: setTab,
            active: active === tab.props.id,
        });
    }))); };
    return (React.createElement(React.Fragment, null,
        React.createElement("div", { className: utils.ShowDesktop }, renderTabbar()),
        React.createElement("div", { className: utils.ShowMobile },
            React.createElement("button", { className: cx(styles.openButton, className, 'openButton', (_b = {}, _b[styles.open] = open, _b)), onMouseDown: function (e) {
                    e.preventDefault();
                    if (open)
                        doClose();
                    else
                        setOpen(true);
                }, ref: theButton, id: ariaLabel }, mobileTitle ? mobileTitle : (_d = (_c = children.filter(function (t) { return t; }).find(function (t) { return t.props.id == active; })) === null || _c === void 0 ? void 0 : _c.props) === null || _d === void 0 ? void 0 : _d.children),
            open && renderTabbar())));
}
export default TabBar;
