import React from 'react';
import cx from '../../../utils/classnames';
import styles from './footnote.module.scss';
import common from '../../../utils/common.module.scss';
import ServiceHandler from '../../../services/service-handler';
export function Footnote(_a) {
    var text = _a.text, className = _a.className, _b = _a.showIcon, showIcon = _b === void 0 ? true : _b;
    return (React.createElement("div", { className: cx(styles.footnote, className, 'footnote'), "aria-label": 'footnote', tabIndex: -1 },
        showIcon && React.createElement("span", { className: styles.footnoteIcon }),
        React.createElement("span", { className: cx(styles.footnoteCopy, common.footnoteWidth, common[ServiceHandler.ConfigService.LANGUAGE_CODE]) }, text)));
}
