import React from 'react';
export var getTitleFromContent = function (content, title) {
    var contentTitle = content.elements.find(function (ele) { return ele.title === title; });
    if (contentTitle != null) {
        var text = contentTitle.value;
        return typeof text === 'string' && ((text === null || text === void 0 ? void 0 : text.includes('<sup>')) || (text === null || text === void 0 ? void 0 : text.includes('<br>')))
            ? React.createElement('span', { dangerouslySetInnerHTML: { __html: text } })
            : text;
        return contentTitle.value;
    }
    else {
        return '';
    }
};
export var getTitleFromExperienceContent = function (content, title) {
    var text = content[title];
    if (text != null) {
        return typeof text === 'string' && ((text === null || text === void 0 ? void 0 : text.includes('<sup>')) || (text === null || text === void 0 ? void 0 : text.includes('<br>')))
            ? React.createElement('span', { dangerouslySetInnerHTML: { __html: text } })
            : text;
    }
    else {
        return '';
    }
};
