var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
var __rest = (this && this.__rest) || function (s, e) {
    var t = {};
    for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p) && e.indexOf(p) < 0)
        t[p] = s[p];
    if (s != null && typeof Object.getOwnPropertySymbols === "function")
        for (var i = 0, p = Object.getOwnPropertySymbols(s); i < p.length; i++) {
            if (e.indexOf(p[i]) < 0 && Object.prototype.propertyIsEnumerable.call(s, p[i]))
                t[p[i]] = s[p[i]];
        }
    return t;
};
import React, { useEffect, useState } from 'react';
import ServiceHandler from '../../../services/service-handler';
import { Route } from 'react-router-dom';
export var RedirectRoute = function (_a) {
    var unauth = _a.unauth, auth = _a.auth, rest = __rest(_a, ["unauth", "auth"]);
    // eslint-disable-next-line @typescript-eslint/no-unused-vars
    var _b = useState(null), isAuthenticated = _b[0], setIsAuthenticated = _b[1];
    var authService = ServiceHandler.AuthenticationService;
    useEffect(function () {
        authService.onIsAuthenticated().then(function (isAuthenticated) {
            setIsAuthenticated(isAuthenticated);
            window.location.pathname = (ServiceHandler.ConfigService.APP_BASEPATH + (isAuthenticated ? auth : unauth)).replace('//', '/');
        });
    }, [authService]);
    return (React.createElement(Route, __assign({}, rest),
        React.createElement("div", null)));
};
