import React, { useEffect } from 'react';
import DatePicker from 'react-datepicker';
import { registerLocale } from 'react-datepicker';
import fr from 'date-fns/locale/fr';
import ServiceHandler from '../../../services/service-handler';
import cx from '../../../utils/classnames';
import styles from './datepicker-with-label.module.scss';
export var DatePickerWithLabel = function (_a) {
    var id = _a.id, label = _a.label, selectedDate = _a.selectedDate, className = _a.className, onChange = _a.onChange;
    useEffect(function () {
        var picker = document.getElementById(id);
        picker.insertAdjacentHTML('afterend', "<label htmlFor='".concat(id, "' class='").concat(styles.datePickerLabel, "'>").concat(label, "</label>"));
    }, []);
    var cfg = ServiceHandler.ConfigService;
    registerLocale('language', cfg.LANGUAGE_COUNTRY_CODE == 'fr-ca' ? fr : null);
    return (React.createElement("div", { className: styles.datePickerGroup },
        React.createElement("i", { className: styles.datePickerIcon }),
        React.createElement(DatePicker, { id: id, name: id, locale: 'language', selected: selectedDate, onChange: onChange, dateFormat: 'yyyy-MM-dd', className: cx(styles.datePickerControl, className), placeholderText: '*', popperPlacement: 'bottom', maxDate: new Date(), formatWeekDay: function (nameOfDay) { return nameOfDay.substr(0, 3); }, renderCustomHeader: function (_a) {
                var date = _a.date, increaseMonth = _a.increaseMonth, decreaseMonth = _a.decreaseMonth, nextMonthButtonDisabled = _a.nextMonthButtonDisabled, prevMonthButtonDisabled = _a.prevMonthButtonDisabled;
                return (React.createElement("div", { className: 'react-datepicker__current-month' },
                    date.toLocaleString(cfg.LANGUAGE_COUNTRY_CODE, { month: 'long' }).replace(/\b(\w)/g, function (s) { return s.toUpperCase(); }),
                    React.createElement("span", null,
                        " ",
                        date.getFullYear()),
                    React.createElement("button", { type: 'button', className: 'react-datepicker__navigation react-datepicker__navigation--previous', "aria-label": 'Previous Month', onClick: decreaseMonth, disabled: prevMonthButtonDisabled }),
                    React.createElement("button", { type: 'button', className: 'react-datepicker__navigation react-datepicker__navigation--next', "aria-label": 'Next Month', onClick: increaseMonth, disabled: nextMonthButtonDisabled })));
            } })));
};
