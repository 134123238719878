var __assign = (this && this.__assign) || function () {
    __assign = Object.assign || function(t) {
        for (var s, i = 1, n = arguments.length; i < n; i++) {
            s = arguments[i];
            for (var p in s) if (Object.prototype.hasOwnProperty.call(s, p))
                t[p] = s[p];
        }
        return t;
    };
    return __assign.apply(this, arguments);
};
import React from 'react';
import { useContent } from '../../../hooks/use-content';
import { CtaLink } from '../../common/cta/cta';
import styles from './terms-conditions-link-view.module.scss';
import { CANOnly } from '../../common/can-only/can-only';
function TermsConditionsLink() {
    var _a = useContent('terms-conditions-link')[0], getValueByTitle = _a.getValueByTitle, getCtaProps = _a.getCtaProps;
    return (React.createElement(CANOnly, null,
        React.createElement("div", { className: styles.container },
            React.createElement(CtaLink, __assign({ href: getValueByTitle('href') }, getCtaProps('heading'))))));
}
export default TermsConditionsLink;
